import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: "/",
    name: 'home',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/home",
    name: 'home',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/pricing",
    name: 'pricing',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/pricing/index.vue"),
  },
  {
    path: "/word-api",
    name: 'wordApi',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/wordApi/index.vue"),
  },
  {
    path: "/detail/:name",
    name: 'detail',
    component: ()=> import(/* webpackChunkName: "about" */ "../views/detail/index.vue"),
  },
  // {
  //   path: "/download",
  //   name: 'download',
  //   component: ()=> import(/* webpackChunkName: "about" */ "../views/download/index.vue"),

  // },
  {
    path: "/404",
    component: ()=> import(/* webpackChunkName: "about" */ "../components/404.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
