import Vue from "vue";
import App from "./App.vue";
import {
  Drawer,
  Loading,
  Message,
  Upload,
  MessageBox,
  Collapse,
  CollapseItem,
  Tooltip,
} from "element-ui";
// import VueI18n from "vue-i18n";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/reset.css";
import "./assets/css/common.css";
import "./assets/css/iconfont.css";
// import 'amfe-flexible';
import router from "./router";
import "./directive/index.js";
Vue.config.productionTip = false;
Vue.use(Tooltip);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Drawer);
Vue.use(Loading.directive);
Vue.use(Upload);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$messageBox = MessageBox;
// 埋点事件
window.$trackEvent = (function () {
  if (process.env.NODE_ENV === "development") {
    return (category, action, opt_label, opt_value) => {
      if (window._hmt) {
        window._hmt.push([
          "_trackEvent",
          "dev_" + category,
          action,
          opt_label,
          opt_value,
        ]);
      }
    };
  } else {
    return (category, action, opt_label, opt_value) => {
      if (window._hmt) {
        window._hmt.push([
          "_trackEvent",
          category,
          action,
          opt_label,
          opt_value,
        ]);
      }
    };
  }
})();
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
