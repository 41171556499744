<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getRem } from '@/utils/index'
getRem(750, 100)
export default {
  mounted() {
    window.onresize = function () {
      getRem(750, 100)
    };
  }
}
</script>
<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
}
</style>
<style scoped lang="less">

</style>