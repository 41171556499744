import Vue from "vue";
Vue.directive('contactPopup', {
    inserted: function (el, binding) {
      let o = el;
      let myDiv = document.getElementById('contactPopup') || document.createElement('div')
      o.onclick = function () {
        // 清空元素
        if (myDiv) {
          myDiv.innerHTML = ''
        } else {
          myDiv = document.createElement('div');
        }
        // 蒙版
        myDiv.id = 'contactPopup'
        myDiv.style.position = 'fixed';
        myDiv.style.top = '0';
        myDiv.style.left = '0';
        myDiv.style.bottom = '0';
        myDiv.style.right = '0';
        myDiv.style.zIndex = '100';
        myDiv.style.backgroundColor = 'rgba(226, 232, 241, 0.9)';
        myDiv.style.display = 'none';
        myDiv.style.textAlign = 'center';
        myDiv.style.zIndex = '2000';
        // 内容
        let myDivSub = document.createElement('div');
        myDivSub.id = 'contactPopupSub'
        myDivSub.style.position = 'fixed';
        myDivSub.style.top = '50%';
        myDivSub.style.left = '50%';
        myDivSub.style.padding = '30px';
        myDivSub.style.transform = 'translate(-50%, -50%)';
        myDivSub.style.backgroundColor = 'white'
        myDivSub.style.borderRadius = '10px'
  
        myDivSub.style.width = '300px';
  
        myDivSub.style.fontSize = "18px"
        myDivSub.style.boxShadow = "0px 4px 16px 4px rgba(236,242,255,0.53);"
  
  
        // 传入文字内容
        if (!binding.value || !binding.value.text) {
          let p1 = document.createElement('p');
          p1.appendChild(document.createTextNode('添加客户经理微信'))
          let p2 = document.createElement('p');
          p2.appendChild(document.createTextNode('为您一对一解答'))
          p1.style.fontSize='20px';
          p1.style.color='#27272C';
          p1.style.marginBottom='10px';
          p2.style.fontSize='20px';
          p2.style.color='#27272C';
          myDivSub.appendChild(p1)
          myDivSub.appendChild(p2)
        } else {
          console.log(binding.value.text, 'sss')
          let p1 = document.createElement('p');
          p1.appendChild(document.createTextNode(binding.value.text))
          myDivSub.appendChild(p1)
        }
  
        // let p1 = document.createElement('p');
        // p1.appendChild(document.createTextNode('添加客户经理微信<br/>为您一对一解答'))
  
        // let p2 = document.createElement('p');
        // p2.appendChild(document.createTextNode('为您一对一解答'))
  
        let img = document.createElement('img');
        img.style.width = "100px"
        img.style.height = "100px"
        img.style.margin = "12px 0"
        img.src = require('../assets/images/kefu.jpg')
  
        let p3 = document.createElement('p');
        p3.appendChild(document.createTextNode('电话咨询：18059273485'))
  
  
        
        myDivSub.appendChild(img)
        myDivSub.appendChild(p3)
  
        myDiv.innerHTML = ''
        myDiv.appendChild(myDivSub)
  
        document.body.appendChild(myDiv);
  
        myDiv.style.display = 'block'
        document.documentElement.style.overflow = "hidden"
      }
      myDiv.onclick = function () {
        myDiv.parentNode.removeChild(myDiv);
        document.documentElement.style.overflow = "scroll"
      }
    }
  })
  Vue.directive('contactPopup2', {
    inserted: function (el, binding) {
      let o = el;
      let myDiv = document.getElementById('contactPopup') || document.createElement('div')
      o.onclick = function () {
        // 清空元素
        if (myDiv) {
          myDiv.innerHTML = ''
        } else {
          myDiv = document.createElement('div');
        }
        // 蒙版
        myDiv.id = 'contactPopup'
        myDiv.style.position = 'fixed';
        myDiv.style.top = '0';
        myDiv.style.left = '0';
        myDiv.style.bottom = '0';
        myDiv.style.right = '0';
        myDiv.style.zIndex = '100';
        myDiv.style.backgroundColor = 'rgba(226, 232, 241, 0.9)';
        myDiv.style.display = 'none';
        myDiv.style.textAlign = 'center';
        myDiv.style.zIndex = '2000';
        // 内容
        let myDivSub = document.createElement('div');
        myDivSub.id = 'contactPopupSub'
        myDivSub.style.position = 'fixed';
        myDivSub.style.top = '50%';
        myDivSub.style.left = '50%';
        myDivSub.style.padding = '30px';
        myDivSub.style.transform = 'translate(-50%, -50%)';
        myDivSub.style.backgroundColor = 'white'
        myDivSub.style.borderRadius = '10px'
  
        myDivSub.style.width = '330px';
  
        myDivSub.style.fontSize = "18px"
        myDivSub.style.boxShadow = "0px 4px 16px 4px rgba(236,242,255,0.53);"
  
  
        // 传入文字内容
        if (!binding.value || !binding.value.text) {
          let p1 = document.createElement('p');
          p1.appendChild(document.createTextNode('该活动仅针对企业API客户:'))
          let p2 = document.createElement('p');
          p2.appendChild(document.createTextNode('•购买大于10万点，再额外赠送15000点；'))
          let p3 = document.createElement('p');
  
          p3.appendChild(document.createTextNode('•量大从优，详询客户经理。'))
          p1.style.fontSize='20px';
          p1.style.color='#27272C';
          p1.style.marginBottom='10px';
          p1.style.textAlign = 'left';
  
          p2.style.fontSize='14px';
          p2.style.color='#27272C';
          p2.style.textAlign = 'left';
          p2.style.lineHeight = '20px';
  
          p3.style.fontSize='14px';
          p3.style.color='#27272C';
          p3.style.textAlign = 'left';
          p3.style.lineHeight = '20px';
  
          myDivSub.appendChild(p1)
          myDivSub.appendChild(p2)
          myDivSub.appendChild(p3)
        } else {
          console.log(binding.value.text, 'sss')
          let p1 = document.createElement('p');
          p1.appendChild(document.createTextNode(binding.value.text))
          myDivSub.appendChild(p1)
        }
  
        // let p1 = document.createElement('p');
        // p1.appendChild(document.createTextNode('添加客户经理微信<br/>为您一对一解答'))
  
        // let p2 = document.createElement('p');
        // p2.appendChild(document.createTextNode('为您一对一解答'))
  
        let img = document.createElement('img');
        img.style.width = "100px"
        img.style.height = "100px"
        img.style.margin = "12px 0"
        img.src = require('../assets/images/kefu.jpg')
  
        let p3 = document.createElement('p');
        p3.appendChild(document.createTextNode('电话咨询：18059273485'))
  
  
        
        myDivSub.appendChild(img)
        myDivSub.appendChild(p3)
  
        myDiv.innerHTML = ''
        myDiv.appendChild(myDivSub)
  
        document.body.appendChild(myDiv);
  
        myDiv.style.display = 'block'
        document.documentElement.style.overflow = "hidden"
      }
      myDiv.onclick = function () {
        myDiv.parentNode.removeChild(myDiv);
        document.documentElement.style.overflow = "scroll"
      }
    }
  })