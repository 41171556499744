export const getFileSuffix = (name) => {
  return name.substring(name.lastIndexOf(".")).toLowerCase();
};

export const imageReader = (file, callback) => {
  const reader = new FileReader();

  reader.onload = () => {
    getImageInfo(reader.result, (width, height) => {
      if (callback) callback(width, height);
    });
  };

  reader.readAsDataURL(file);
};
export const getImageInfo = (src, callback) => {
  var img = new Image();

  img.onload = () => {
    if (callback) {
      callback(img.width, img.height);
    }
  };

  img.src = src;
};

export const errMsg = (errorCode) => {
  if (errorCode === 1009) {
    return "图片未检测到人脸，不能进行动态人脸处理哦!";
  } else if (errorCode === 1004) {
    return "图片分辨率不能小于64x64";
  } else if (errorCode === 1010) {
    return "图片分辨率不能大于9216x9216";
  } else if (errorCode === 300) {
    return "图片涉及色情内容";
  } else if (errorCode === 301) {
    return "图片涉及暴力内容";
  } else if (errorCode === 302) {
    return "图片涉及政治内容";
  } else {
    return "图片处理失败 ，请重试~";
  }
};
export const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");

  var r = window.location.search.substr(1).match(reg);

  if (r != null) return unescape(r);
  return null;
};
export const getRem = (design_w, unit) => {
  var html = document.getElementsByTagName("html")[0];
  var real_w = document.documentElement.clientWidth;
  (real_w > design_w) && (real_w = design_w);
  html.style.fontSize = real_w / design_w * unit + "px";
}

// export const trackEvent = (category,action,opt_label,opt_value) => {
//   if(window._hmt){
//     window._hmt.push(['_trackEvent', category, action, opt_label, opt_value]);
//   }
// }